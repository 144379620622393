.custom-actions {
    &__item {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;
    }
    @include mq($until: tablet) {
        &__item {
            p {
                margin-bottom: 10px;
            }
        }
    }
    @include mq($from: tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__item {
            gap: 0 20px;
        }
        &--end {
            justify-content: flex-end;
        }
    }
}