.gov-page {
    overflow: hidden;
    min-height: 100vh;

    background-color: gov-colors('grey-bg');
    color: gov-colors('grey-mid');
    font-family: $gov-font-family-primary;
    font-size: rem-calc(15);
    font-weight: 400;
    letter-spacing: em-calc(0.2);

    -webkit-font-smoothing: antialiased;
}
