.gov-hamburger {
    @extend .gov-button;
    @extend .gov-button--inversed;

    position: relative;

    padding: rem-calc(10);
    padding-right: rem-calc(40px);

    &:before,
    &:after {
        @include pseudo();
        right: rem-calc(10);

        height: 2px;

        background-color: gov-colors('white');
        border-radius: 2px;

        transition: 200ms ease-out;
    }

    &:before {
        top: rem-calc(14);

        width: rem-calc(20);
    }

    &:after {
        top: rem-calc(21);

        width: rem-calc(15);
    }

    &.is-active {
        background-color: transparent !important;
        border-color: transparent !important;

        &:before,
        &:after {
            top: rem-calc(17);
            width: rem-calc(20);
        }

        &:before {
            transform: rotate(45deg) translateZ(0);
        }

        &:after {
            transform: rotate(-45deg) translateZ(0);
        }
    }

    @include mq($from: mobile-landscape) {
        display: none;
    }
}
