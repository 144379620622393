.u-full-width {
    width: 100%;
}

.u-sr-only {
    display: none !important;
}


/*  HIDE elements below breakpoint
------------------------------------------------------------------ */
.u-hide--portable {
    @include mq($until: portable) {
        display: none;
        visibility: hidden;
    }
}

.u-hide--tablet-landscape {
    @include mq($until: tablet-landscape) {
        display: none;
        visibility: hidden;
    }
}

.u-hide--tablet {
    @include mq($until: tablet) {
        display: none;
        visibility: hidden;
    }
}

.u-hide--mobile-landscape {
    @include mq($until: mobile-landscape) {
        display: none;
        visibility: hidden;
    }
}

.u-hide--mobile {
    @include mq($until: mobile) {
        display: none;
        visibility: hidden;
    }
}

.u-hide--palm {
    @include mq($until: palm) {
        display: none;
        visibility: hidden;
    }
}
