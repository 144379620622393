ul, .gov-list {
    margin-left: rem-calc(5);

    list-style-type: disc;
    list-style-position: inside;
}

ol, .gov-ordered-list {
    margin-left: rem-calc(5);

    list-style-type: decimal;
    list-style-position: inside;
}
