.gov-preview-tile {
    & > *:last-child {
        margin-bottom: 0;
    }

    &__icon {
        margin-bottom: rem-calc(15);

        color: gov-colors('blue');
    }

    .gov-badge + p {
        margin-top: rem-calc(8);
    }
}
