@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

:root {
    --gov-color-blue               : #2362a2;
    --gov-color-grey-dark          : #3b3b3b;
    --gov-color-white              : #ffffff;

    --gov-color-blue-dark          : #254e80;
    --gov-color-blue-focus         : #007bff;
    --gov-color-blue-light         : #3077b7;
    --gov-color-blue-inactive-1    : #91b0d0;
    --gov-color-blue-inactive-2    : #d3dfec;
    --gov-color-blue-inactive-3    : #edf0f2;
    --gov-color-blue-hover         : #e5ebf0;

    --gov-color-grey-mid           : #686868;
    --gov-color-grey-light         : #a8a8a8;
    --gov-color-grey-inactive      : #dddddd;
    --gov-color-grey-bg            : #f5f5f5;

    --gov-color-yellow             : #ecae1a;
    --gov-color-yellow-dark        : #dd9f0c;

    --gov-color-error              : #c52a3a;
    --gov-color-success            : #6fbd2c;

    --gov-color-grey-dark-rgb      : #{hexToRGB(#3b3b3b)};
    --gov-color-white-rgb          : #{hexToRGB(#ffffff)};
}

$gov_colors: (
    // primary
    'blue'               : --gov-color-blue,
    'grey-dark'          : --gov-color-grey-dark,
    'white'              : --gov-color-white,

    //  blue
    'blue-dark'          : --gov-color-blue-dark,
    'blue-focus'         : --gov-color-blue-focus,
    'blue-light'         : --gov-color-blue-light,
    'blue-inactive-1'    : --gov-color-blue-inactive-1,
    'blue-inactive-2'    : --gov-color-blue-inactive-2,
    'blue-inactive-3'    : --gov-color-blue-inactive-3,
    'blue-hover'         : --gov-color-blue-hover,

    //  neutral
    'grey-mid'           : --gov-color-grey-mid,
    'grey-light'         : --gov-color-grey-light,
    'grey-inactive'      : --gov-color-grey-inactive,
    'grey-bg'            : --gov-color-grey-bg,

    //  yellow
    'yellow'             : --gov-color-yellow,
    'yellow-dark'        : --gov-color-yellow-dark,

    //  warning
    'error'              : --gov-color-error,
    'success'            : --gov-color-success,

    //  rgb
    'grey-dark-rgb'      : --gov-color-grey-dark-rgb,
    'white-rgb'          : --gov-color-white-rgb,
);

@function gov-colors($color) {
    @return var(map-get($gov_colors, $color));
}
