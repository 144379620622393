.custom-intro {
  background: gov-colors('blue-light');
  &__title {
    color: gov-colors('white');
  }
  &__perex {
    max-width: 80ch;
    margin-bottom: rem-calc(30);
    font-weight: 700;
    p {
      gap: rem-calc(16);
      font-size: rem-calc(20);
      color: gov-colors('white');
      line-height: #{20/12};
      font-weight: 400;
      span {
        display: block;
      }
    }
  }
  @include mq($from: tablet) {
    &__actions {
      display: flex;
      gap: 29px;
    }
    &__perex {
      margin-bottom: rem-calc(70);
      p {
        display: flex;
        align-items: center;
      }
    }
  }
}
