.gov-header {
  $this: &;

  overflow: initial;

  background-color: gov-colors('blue');

  &.is-fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    @include z-index('modal', 10);
  }

  &__holder {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(17);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__separator {
    display: none;

    position: static;

    margin: 0 -1.25rem;
    width: auto;

    height: 1px;
    border: none;
    border-bottom: 1px solid gov-colors('blue-light');

    @include mq($from: 900px) {
      display: block;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    text-decoration: none;

    @include hover() {
      #{$this}__site-name {
        color: white;
      }
    }
  }

  &__site-name {
    padding-left: rem-calc(10);

    font-size: rem-calc(21);
    font-weight: 700;
    color: gov-colors('white');

    &--large {
      font-size: rem-calc(26);
    }

    &--dark {
      color: gov-colors('grey-dark');
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    width: 100%;

    margin-top: rem-calc(-25);

    @media screen and (min-width: em-calc(900)) {
      margin-top: 0;

      width: auto;
    }
  }

  &__item {
    margin-left: rem-calc(15);

    @media screen and (min-width: em-calc(900)) {
      margin-left: rem-calc(30);
    }

    &--login {
      display: none;

      @media screen and (min-width: em-calc(900)) {
        display: block;
      }
    }

    &:nth-child(1) {
      order: 3;

      width: 100%;

      margin-left: 0;

      @media screen and (min-width: em-calc(900)) {
        order: unset;

        width: auto;

        margin-left: rem-calc(30);
      }
    }

    &--mobile {
      @media screen and (min-width: em-calc(900)) {
        display: none;
      }
    }
  }

  &__lang {
    order: 3;
    margin-left: rem-calc(10);

    @media screen and (min-width: em-calc(900)) {
      margin-left: rem-calc(25);
    }
  }

  &__fixed-items {
    display: flex;
    align-items: center;

    @media screen and (max-width: em-calc(900)) {
      position: relative;

      top: rem-calc(-18);
      right: -(rem-calc(10));
      z-index: 10;
    }
  }

  &__hamburger {
    display: inline-block;

    position: relative;

    @include button-core();

    padding: 0;
    padding-left: rem-calc(8);
    padding-right: rem-calc(40);

    height: rem-calc(30);

    font-size: rem-calc(14);
    color: gov-colors('white');
    text-align: center;
    transform: rotate(0deg);

    &:focus {
      background-color: gov-colors('blue');
    }

    @include hover() {
      background-color: transparent;
    }

    &.active {
      span {
        &:nth-child(1) {
          top: rem-calc(15);
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          width: rem-calc(18);
          top: rem-calc(15);
          transform: rotate(-45deg);
        }
      }
    }

    span {
      display: block;
      position: absolute;
      height: rem-calc(2);
      width: rem-calc(18);
      background: gov-colors('white');
      border-radius: 2px;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.2s ease-out;

      &:nth-child(1) {
        right: rem-calc(12);
        top: rem-calc(10);
      }

      &:nth-child(2) {
        top: rem-calc(19);
        width: rem-calc(12);
        right: rem-calc(12);
      }
    }
  }

  .gov-logo {
    font-size: rem-calc(15);
    inline-size: 150px;
    @media screen and (min-width: em-calc(500)) {
      font-size: rem-calc(20);
      inline-size: 300px;
    }
    @media screen and (min-width: em-calc(900)) {
      font-size: rem-calc(25);
      inline-size: 400px;
    }
  }

  .gov-search {
    margin-bottom: 0;
    padding: 0;

    [type='text'] {
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(9);

      font-size: rem-calc(16);
    }
  }

  &__nav-holder {
    padding-top: rem-calc(120);

    @media screen and (min-width: em-calc(900)) {
      position: relative;
      left: rem-calc(-15);

      display: flex;
      justify-content: space-between;
      padding-top: 0;
      width: calc(100% + 30px);
    }
  }

  &__nav {
    display: none;

    @media screen and (min-width: em-calc(900)) {
      display: block;
    }

    background-color: gov-colors('blue');

    &-logo {
      display: none;

      &.active {
        position: absolute;
        top: rem-calc(20);
        left: rem-calc(30);

        display: block;
      }
    }

    &.active {
      display: block;

      z-index: 9;
      position: absolute;
      top: 0;
      bottom: 0;

      overflow-y: scroll;
      padding-bottom: rem-calc(20);
      height: 100vh;
      width: 100%;

      @media screen and (min-width: em-calc(480)) {
        right: 0;

        width: rem-calc(360);
      }
    }
  }

  &__overlay {
    display: none;

    z-index: 8;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5);

    &.active {
      display: block;
      min-height: 100vh;
    }
  }

  &__link {
    .gov-link {
      position: relative;

      display: block;

      padding: rem-calc(15 48 15 36);

      @media screen and (min-width: em-calc(900)) {
        padding: rem-calc(25 20);

        text-decoration: none;

        &:before {
          @include pseudo();
          top: -1px;
          left: rem-calc(20);
          right: rem-calc(20);

          height: 2px;

          background-color: gov-colors('white');

          transform: scale(0, 1);
          transition: transform 256ms ease-out;
          will-change: transform;
        }

        &--external,
        &[target='_blank'] {
          &:before {
            right: rem-calc(28);
          }

          &:after {
            position: relative;
            top: rem-calc(-8);

            max-height: 16px;
          }
        }
      }
    }

    @include hover() {
      .gov-link:before {
        transform: scale(1, 1);
      }
    }

    &.is-active {
      .gov-link:before {
        transform: scale(1, 1);
      }
    }
  }

  @media screen and (min-width: em-calc(900)) {
    &__link--active {
      .gov-link {
        &:before {
          @include pseudo();
          top: -1px;
          left: 0;
          right: 0;

          height: 2px;

          background-color: gov-colors('white');
        }
      }
    }
  }
}
