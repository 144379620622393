$class: '.gov-stepper';

#{$class} {
    padding-bottom: rem-calc(10);

    &__step {
        padding: rem-calc(20) rem-calc(25);

        text-align: center;

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    &__step-number {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: rem-calc(20);
        padding: rem-calc(10);
        max-width: rem-calc(45);

        background-color: gov-colors('blue-hover');
        border-radius: 100%;

        .o-perex {
            color: gov-colors('grey-dark');
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    /*  As block
    ------------------------------------------------------------------ */
    &--as-block {
        #{$class}__step {
            position: relative;

            margin-bottom: rem-calc(40);
            padding: 0;
            padding-top: rem-calc(10);
            padding-left: rem-calc(70);

            text-align: left;

            &:last-child {
                margin-bottom: 0;
            }
        }

        #{$class}__step-number {
            position: absolute;
            left: 0; top: 0;

            width: rem-calc(45);
        }

        @include mq($until: palm) {
            #{$class}__step {
                padding-left: 0;
            }

            #{$class}__step-number {
                position: initial;

                &:before,
                &:after {
                    @include pseudo();

                    top: rem-calc(22);

                    height: 1px;

                    background-color: gov-colors('grey-inactive');
                }

                &:before {
                    left: 0; right: calc(50% + 23px);
                }

                &:after {
                    left: calc(50% + 23px); right: 0;
                }
            }
        }
    }
    /*  Left
    ------------------------------------------------------------------ */
    &--left {
        margin-left: -(rem-calc(25));
        margin-right: -(rem-calc(25));
        #{$class}__step {
            text-align: left;
        }   
        #{$class}__step-number {
            margin-left: 0;
        }
    }
}
