.gov-complex-icon {
    color: currentColor;
    font-size: rem-calc(48);
    line-height: 0.5;

    &:before {
        font-family: 'gov-complex-icons';
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        line-height: 1;
        text-transform: none;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /*  Sizes
    ------------------------------------------------------------------ */
    &--16x16 {
        font-size: rem-calc(16);
    }

    &--24x24 {
        font-size: rem-calc(24);
    }

    &--32x32 {
        font-size: rem-calc(32);
    }

    &--56x56 {
        font-size: rem-calc(56);
    }

    &--64x64 {
        font-size: rem-calc(64);
    }

    /*  Icons
    ------------------------------------------------------------------ */
    &--car:before {
        content: '\61';
    }

    &--covid:before {
        content: '\62';
    }

    &--doc-health:before {
        content: '\63';
    }

    &--doc-search:before {
        content: '\64';
    }

    &--doc-stamp:before {
        content: '\65';
    }

    &--doc-taxes:before {
        content: '\66';
    }

    &--graduate:before {
        content: '\74';
    }

    &--house:before {
        content: '\67';
    }

    &--id-card:before {
        content: '\68';
    }

    &--illness:before {
        content: '\75';
    }

    &--info:before {
        content: '\69';
    }

    &--jobloss:before {
        content: '\77';
    }

    &--map:before {
        content: '\6a';
    }

    &--new-comments:before {
        content: '\6b';
    }

    &--newborn:before {
        content: '\6c';
    }

    &--packet:before {
        content: '\6d';
    }

    &--planet:before {
        content: '\6e';
    }

    &--pregnant:before {
        content: '\6f';
    }

    &--review:before {
        content: '\70';
    }

    &--rings:before {
        content: '\71';
    }

    &--senior:before {
        content: '\76';
    }

    &--tombstone:before {
        content: '\72';
    }

    &--vacation:before {
        content: '\73';
    }
}
