
//  margin-right
.u-me--unset {
  margin-right: 0 !important;
}

.u-me--10 {
  margin-right: rem-calc(10);
}

.u-me--20 {
  margin-right: rem-calc(20);
}

.u-me--25 {
  margin-right: rem-calc(25);
}

.u-me--30 {
  margin-right: rem-calc(30);
}

.u-me--40 {
  margin-right: rem-calc(40);
}

.u-me--50 {
  margin-right: rem-calc(50);
}

.u-me--60 {
  margin-right: rem-calc(60);
}

.u-me--70 {
  margin-right: rem-calc(70);
}

.u-me--95 {
  margin-right: rem-calc(95);
}

//  margin-left
.u-ms--unset {
  margin-left: 0 !important;
}

.u-ms--10 {
  margin-left: rem-calc(10);
}

.u-ms--20 {
  margin-left: rem-calc(20);
}

.u-ms--25 {
  margin-left: rem-calc(25);
}

.u-ms--30 {
  margin-left: rem-calc(30);
}

.u-ms--40 {
  margin-left: rem-calc(40);
}

.u-ms--50 {
  margin-left: rem-calc(50);
}

.u-ms--60 {
  margin-left: rem-calc(60);
}

.u-ms--70 {
  margin-left: rem-calc(70);
}

.u-ms--95 {
  margin-left: rem-calc(95);
}

//  margin-top
.u-mt--unset {
  margin-top: 0 !important;
}

.u-mt--10 {
  margin-top: rem-calc(10);
}

.u-mt--20 {
  margin-top: rem-calc(20);
}

.u-mt--25 {
  margin-top: rem-calc(25);
}

.u-mt--30 {
  margin-top: rem-calc(30);
}

.u-mt--40 {
  margin-top: rem-calc(40);
}

.u-mt--50 {
  margin-top: rem-calc(50);
}

.u-mt--60 {
  margin-top: rem-calc(60);
}

.u-mt--70 {
  margin-top: rem-calc(70);
}

.u-mt--95 {
  margin-top: rem-calc(95);
}
