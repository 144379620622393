blockquote, .gov-blockquote {
    padding-left: rem-calc(20);

    background-color: gov-colors('white');
    border-left: 4px solid gov-colors('grey-dark');
    font-size: 12pt;

    break-inside: avoid;
    page-break-inside: avoid;

    position: initial;

    &:before,
    &:after {
        display: none;
    }
}
