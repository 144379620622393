// Styly pro export formu do PDF
@media print {

  // Menší tučné nadpisy
  .formsContent strong {
    font-size: 11px !important;
    margin-top: 20px !important;
    margin-bottom: -10px !important;
  }

  .radioAlone, .radioClasic {
    .gov-form-control--custom {
      position: relative;
    }
  }
  // Label inputů
  $class: ".gov-form-control";
  #{$class} {

    margin: 0.1rem !important;
    //position: relative;

    &:not(#{$class}--custom) {
      #{$class}__label {
        font-size: rem-calc(10) !important;
        margin: 0.2rem 0 0.2rem 0 !important;
        padding: 0 !important;
        color: black !important;
        font-weight: normal;
        opacity: 1 !important;
      }
    }

    &--custom {
      position: relative;

      // Checkbox
      #{$class}__checkbox {
        position: relative;

        z-index: 1 !important;
        opacity: 1 !important;
        margin-top: 10px !important;
        background: white;

        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border: 1px solid black;

        &:checked {
          background-color: gray;
        }
      }

      //RadioButton
      #{$class}__radio {

        z-index: 1 !important;
        opacity: 1 !important;
        margin-top: 10px !important;

        background: white;

        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border: 1px solid black;
        border-radius: 50%;

        &:checked {
          background-color: gray;
        }
      }

      #{$class}__label {
        font-size: rem-calc(10) !important;
        margin-left: 1.5rem !important;
        margin-top: 10px !important;
        color: black !important;
        font-weight: normal;

        &.noLeftPadding {
          margin-left: 0 !important;
          margin-bottom: 5px !important;
        }
      }

      &.not-empty,
      &.default-behaviour-unset {
        #{$class}__label {
          margin: 0 !important;
          padding: 0 !important;
          padding-top: 0.2rem !important;
          font-size: rem-calc(10) !important;
        }
      }
    }

    &__message {
      display: none !important;
    }

  }

  //Inputy
  [type="text"],
  [type="password"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="month"],
  [type="week"],
  [type="email"],
  [type="number"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="url"],
  [type="color"] {
    font-size: rem-calc(10) !important;
    padding: 0.2rem 0.4rem !important;
    margin: 0 !important;
    height: 24px !important;
    background: white !important;
    color: black !important;
  }

  // Textarea
  textarea {
    font-size: rem-calc(12) !important;
    padding: 0.2rem !important;
    margin: 0 !important;
    background: white !important;
    color: black !important;
  }

  .section-header-nav {
    display: none !important;
  }
  .bulb-info-wrap {
    display: none !important;
  }

  .custom-form {
    font-size: 10px !important;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    //margin: 10px 0 0 0 !important;

    &__inner {
      margin: 2rem 0.1rem;
      padding: 2rem 0.5rem 0.5rem 0.5rem !important;
    }

    &__wrap {
      gap: 5px;
      width: 100%;
      justify-content: center !important;
      align-items: center !important;
    }

    &__wrap > div {
      flex-basis: 80% !important;
      gap: 5px !important;
      width: 100% !important;
    }

    &__wrap > strong {
      height: 100%;
      font-size: 10px !important;
      flex-basis: 20% !important;
      margin: 0 !important;
      padding: 0.2rem 0 0 0 !important;
    }
  }

  .custom-form__wrap {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    & > div {
      flex: 1 1;
    }
  }
  .custom-form__wrap--special > :first-child {
    flex: 0 0 auto;
    width: calc(66.6666% - 10px);
  }

  .custom-form__wrap.alignLeft > strong {
    flex-basis: 30% !important;
  }

  .custom-form__wrap.alignLeft > div {
    flex-basis: 35% !important;
  }

  .gov-form-control {
    select {
      &[aria-disabled="true"],
      &:disabled {
        ~ .gov-form-control__label {
          font-size: rem-calc(10) !important;
          color: gov-colors("grey-dark") !important;
          padding: 0 !important;
        }
      }

      margin-top: 3px !important;
    }

    select {
      font-size: rem-calc(10) !important;
      padding: 0.2rem !important;
      height: 24px !important;
      background: white !important;
      color: black !important;
    }

    .gov-select {
      display: flex !important;
      flex-direction: column-reverse !important;
      color: black !important;

      &:after {
        display: none !important;
      }
    }

    .gov-autocomplete {
      display: flex !important;
      flex-direction: column-reverse !important;
      color: black !important;
    }
  }

  .gov-form-control.not-empty {
    select[aria-disabled="true"] ~ .gov-form-control__label,
    select:disabled ~ .gov-form-control__label {
      padding: 0 !important;
    }
  }

  .u-mb--10 {
    font-size: 20px;
  }

  //.u-mb--30 {
  //  margin: 1rem !important;
  //}

  .u-mb--40 {
    margin: 0rem !important;
    font-size: 14px;
  }

  .gov-form-control.not-empty {
    select[aria-disabled="true"] ~ .gov-form-control__label,
    select:disabled ~ .gov-form-control__label {
      padding: 0 !important;
    }
  }

  .selectAutocompleteContainer {
    padding-left: 0rem !important;
    font-size: rem-calc(10) !important;
    min-height: 10px !important;
    height: 24px !important;
    margin-top: 2px !important;

    .gov-select {
      padding: 0rem !important;
      font-size: rem-calc(10) !important;
      height: 24px !important;
      min-height: 10px !important;
      background: white !important;
      color: black !important;
    }

    .sa-valueContainer {
      width: 100% !important;
      padding: 0.1rem 0.2rem !important;
      font-size: rem-calc(10) !important;
      height: 24px !important;

      input {
        height: 20px !important;
      }

      * {
        color: black !important;
      }
    }

    .selectAutocompleteInput {
      opacity: 0;
    }
  }

  .selectAutocompleteContainer.disabledAutocomplete .gov-select {
    padding-left: 0rem !important;
    font-size: rem-calc(10) !important;
    min-height: 10px !important;
    height: 24px !important;
  }
  .autocomplete-wrap {
    display: flex;
    flex-direction: column-reverse;
  }

  textarea.gov-form-control__input {
    padding: 0.5rem !important;
  }

  .custom-detail__main {
    p, a {
      font-size: rem-calc(10) !important;
      margin-bottom: 0;
    }
  }

  .custom-detail__side {
    display: none
  }

  .custom-detail__bottom {
    display: none
  }

  .gov-header__left {
    margin-left: 30px;
  }

  .formsContent textarea.gov-form-control__input:disabled + label {
    background-color: white !important;
  }
  .custom-form__title {
    letter-spacing: 0;
    color: black;
  }

  .gov-header {
    display: none;
  }
  .gov-footer {
    display: none;
  }
  .formnavigation {
    display: none;
  }

  .formsContent {
    button, .btnwrap {
      display: none !important;
    }
  }


  .gov-container--wide{
    padding: 0!important;
    background: none!important;
  }
  body{
    background: none!important;
  }
}

