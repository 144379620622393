@charset 'UTF-8';

@font-face {
  font-family: 'gov-basic-icons';
  src: url($path + 'fonts/icons/gov-basic-icons.woff2' + '?v=' + $version) format('woff2');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
    content: attr(data-icon);

    font-family: 'gov-basic-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    line-height: 1;
    text-transform: none !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
