/* [Elements] - Forms
------------------------------------------------------------------ */

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  display: block;
  padding: rem-calc(25 20 11);
  width: 100%;

  background-color: gov-colors('white');
  border: 1px solid gov-colors('grey-mid');
  border-radius: 3px;
  color: gov-colors('grey-dark');
  cursor: pointer;
  font-family: inherit;
  font-size: rem-calc(14);
  letter-spacing: 1.02px;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:active {
    border-color: gov-colors('blue');
    outline: 0;
  }

  @include hover() {
    border-color: gov-colors('blue');
    outline: 0;
  }

  &:focus {
    border-color: unset;
    box-shadow: 0 0 0 $gov-outline-width gov-colors('blue-focus');
    outline: 0;
  }

  &[aria-disabled='true'],
  &:disabled {
    cursor: not-allowed;

    background-color: gov-colors('grey-bg');
    border-color: gov-colors('grey-light');
    color: gov-colors('grey-light');
  }

  @include input-placeholder {
    color: gov-colors('grey-light');
  }
}

textarea {
  min-height: rem-calc(115);
}
