.custom-form {
  position: relative;
  padding: 30px 14px 15px;
  border: 1px solid gov-colors("grey-light");
  background: white;
  //&:hover{
  //  //background: rgba(50,50,50,0.05);
  //  background: gov-colors('blue-hover');
  //}

  &__title,
  &__inner-title {
    background: white;
    position: absolute;
    left: 22px;
    top: -10px;
    margin: 0;
    padding: 2px 6px;
    color: black;
    letter-spacing: 0.07rem;
    font-weight: 400;
  }
  &__inner {
    position: relative;
    border: 1px solid #dddddd;
    margin: 30px -4px;
    padding: 30px 14px 15px;
  }
  &__inner--special {
    min-height: 240px;
  }
  &--special {
    position: relative;
    border-bottom: none;
    padding-bottom: 80px;
    &::before {
      content: '';
      position: absolute;
      bottom: -42px;
      left: 0;
      right: 0;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='1298' height='83' viewBox='0 0 1298 83' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23A8A8A8' d='M.768 40.413h590.959L632.171.642l82.11 81.718 39.923-41.947h543.429' fill='none' fill-rule='evenodd'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      height: 83px;
      background-position: center 0;
    }
  }
  @include mq($from: tablet) {
    //padding: 40px 14px 15px;
    &--special {
      padding-bottom: 80px;
    }
    &__wrap {
      display: flex;
      gap: 30px;
      > div {
        flex: 1 1 auto;
      }
    }
    &__inner {
      margin: 30px -4px;
    }
    &__wrap--special {
      > :first-child {
        flex: 0 0 auto;
        width: calc(66.6666% - 10px);
      }
    }
  }
}
