.custom-detail {
    &__top {
        margin-bottom: rem-calc(20);
    }
    
    &__main {
        background: $c-gov-secondary-200;
        border: 1px solid $c-gov-primary-400;
        padding: 15px 20px;
        p {
            margin: 0;
            span {
                display: block;
            }
        }
    }
    @include mq($from: tablet) {
        &__bottom,
        &__top {
            display: flex;
            align-items: start;
            justify-content: space-between;
            gap: 60px;
        }
        //&__alert,
        &__main {
            flex: 0 1 auto;
            width: 100%;
            display: flex;
            p{
                flex-grow: 1;
            }
        }
        &__side{
            margin-right: 15px;
            min-width: 400px;
        }
    }
}
