$c-gov-blue: #2362a2;
$c-gov-error: #c52a3a;
$c-gov-success: #6fbd2c;
$c-gov-yellow: #ecae1a;

:root {
  --toastify-color-success: #6fbd2c !important;
  --toastify-color-warning: #ecae1a !important;
  --toastify-color-error: #c52a3a !important;
}


$c-gui-blue: $c-gov-blue; // #2262a2;
$c-gui-blue-dark: #15355e;
$c-gui-blue-light: #ecf7ff;
$c-gui-yellow: #fad47b;
$c-gui-yellow-light: #fef8eb;
$c-gui-green: $c-gov-success;
$c-gui-green-hover: #3a6217;
$c-gui-red: $c-gov-error;
$c-gui-red-hover: #9e0615;

$c-gov-yellow-100: #fef9ef;

$c-gov-primary-100: #ecf7ff;
$c-gov-primary-200: #d9efff;
$c-gov-primary-300: #b5d9f3;
$c-gov-primary-400: #91C3E7;
$c-gov-primary-500: #6DADDB;
$c-gov-primary-600: #2362a2;
$c-gov-primary-700: #254e80;
$c-gov-primary-800: #15355e;
$c-gov-primary-900: #0d213a;

$c-gov-secondary-100: #FCFCFC;
$c-gov-secondary-200: #fafafa;
$c-gov-secondary-300: #f5f5f5;
$c-gov-secondary-400: #EFEFEF;
$c-gov-secondary-500: #EAEAEA;
$c-gov-secondary-600: #a8a8a8;
