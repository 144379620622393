.gov-actuality {
  display: flex;
  flex-direction: column;

  &--button {
    margin-top: 20px;
  }

  &--link {
    display: block;
    text-decoration: none;
    &:hover {
      background-color: #3b3b3b27;
    }
    border-bottom: 1px solid #2362a2;
    &:last-child {
      border: none;
    }
  }
  &--single {
    padding: 20px;
  }

  &--title {
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 300;
  }

  &--single-title {
    color: #2362a2;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.0125em;
    line-height: 1.2;
    text-overflow: ellipsis;
  }
  &--date {
    color: #434343;
    padding-top: 3px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 0.9375rem;
    letter-spacing: 0.05em;
    line-height: 1;
    font-weight: 400;
  }
  &--text {
    color: var(--gov-color-grey-mid);
    word-wrap: break-word;
    font-size: 0.9375rem;
    font-weight: 400;
    letter-spacing: 0.0125em;
    line-height: 1.6;
    text-overflow: ellipsis;
  }
  @media screen and (min-width: 690px) {
    &--links {
      display: flex;
    }

    &--link {
      flex: 1;
      display: flex;
      border-bottom: none;
      border-right: 1px solid #2362a2;
    }

    &--single {
      padding: 20px;
      flex: 1;
    }
  }
  @media screen and (min-width: 870px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;

    &--links {
      grid-row: 1/3;
      grid-column: 2/3;
      flex-direction: column;
    }
    &--link {
      border-bottom: 1px solid #2362a2;
      border-right: none;
    }
  }
  @media screen and (min-width: 990px) {
    grid-auto-columns: 1fr;
    grid-template-columns: 0.3fr 1fr;
    grid-template-rows: 0.3fr 1fr;

    &--links {
      display: flex;
      flex-direction: row;
    }

    &--link {
      flex: 1;
      display: flex;
      border-bottom: none;
      border-right: 1px solid #2362a2;
    }

    &--single {
      padding: 20px;
      flex: 1;
    }
  }
}
