.gov-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--align-end {
        align-items: flex-end;

        @include mq($until: 36em) {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    &--justify-start {
        justify-content: flex-start;
    }

    &--justify-center {
        justify-content: center;
    }

    &--justify-end {
        justify-content: flex-end;

        & > .gov-button--primary-outlined {
            margin-top: rem-calc(50);
        }
    }
}
