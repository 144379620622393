@import "colors";

$primary: $c-gov-blue;
//$link-decoration: none;
//$link-hover-decoration: underline;
//@import "~bootstrap/scss/bootstrap";

@import "../assets/scss/config";
@import "../assets/scss/styles";
@import "../assets/scss/print";
@import "utils";

//a, button {
//  &:focus {
//    //border-color: transparent !important;
//    outline: 0 !important;
//    box-shadow: none !important;
//  }
//}
.react-datepicker__close-icon {
  right: 25px !important;
  z-index: 1;
  padding: 0 6px;

  &:after {
    color: black !important;
    background-color: transparent !important;
    font-size: 20px;
  }
}

.gov-sortable-table__trigger {
  &:after {
    opacity: 0.4;
  }

  &--desc,
  &--asc {
    &:after {
      opacity: 1;
    }
  }
}

.gov-breadcrumbs__item .gov-link:visited {
  color: var(--gov-color-blue);
}

.gov-modal__content-inner {
  overflow-y: auto;
}

.gov-alert:before {
  font-size: 1.25rem;
}

.gov-alert--success:before {
  color: var(--gov-color-success);
}

.custom-form__title + .custom-form__inner,
.custom-form__inner > .custom-form__inner {
  margin-top: 0;
}

.custom-form__wrap {
  align-items: flex-start;
}

@media (min-width: 52em) {
  .custom-form__wrap > div {
    flex: 1 1 0;
  }
}

.custom-form__wrap.alignLeft > div {
  flex: 0 1 auto;
}

.custom-form__wrap.alignRight {
  justify-content: end;

  & > div {
    flex: 0 1 auto;
  }
}

.gov-form-control--custom .gov-form-control__label {
  text-decoration: none;
  padding-right: 15px;
  text-transform: none;
}

.gov-form-control__label.noLeftPadding {
  padding-left: 0;
  margin-bottom: 10px;
}

.gov-header__nav-holder {
  justify-content: start;

  .gov-header__link {
    margin-right: 30px;

    .gov-link {
      color: var(--gov-color-white);
    }
  }
}

.container_bg_white {
  background: white;
  padding: 1.5rem;
  overflow: auto;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  padding-top: 0.8rem;
}

.custom-form__title,
.custom-form__inner-title {
  background: white;
}

select:disabled {
  opacity: 0.7;
}

.gov-form-control {
  .datepickerinput-disabled ~ .gov-form-control__label,
  select[aria-disabled="true"] ~ .gov-form-control__label,
  select:disabled ~ .gov-form-control__label,
  textarea:disabled ~ .gov-form-control__label,
  input:disabled ~ .gov-form-control__label {
    opacity: 0.7;
    padding-top: 0.4375rem;
    font-size: 0.75rem;
    color: var(--gov-color-grey-dark);
    background-color: var(--gov-color-grey-bg);
  }
}


.gov-header__nav.active {
  overflow: hidden;
}

.gov-header__hamburger:hover {
  color: var(--gov-color-white) !important;
}

.overflowVisible {
  overflow: visible;
}

.stickyFormActions {
  position: sticky;
  bottom: 0;
  background: #f6f5f5;
  z-index: 99;

  &:before {
    content: "";
    background: #f6f5f5;
    z-index: -1;
    position: absolute;
    top: -20px;
    bottom: -20px;
    right: calc((100vw - 20px - 100%) / -2);
    left: calc((100vw - 20px - 100%) / -2);
  }
}

.errorPanelFix {
  max-height: 200px;
  overflow-y: scroll;
  margin-bottom: 20px;
  background: white;
  padding: 10px;
  border: 1px solid black;
}

.blockGovError {
  & > div {
    & > .custom-form {
      border-color: var(--gov-color-error);

      & > .custom-form__title {
        color: var(--gov-color-error);
      }
    }
  }
}

.blockGovErrorText {
  * {
    color: var(--gov-color-error);
  }

  margin-bottom: 15px;
}

.tableActionDropdown {
  z-index: 2;
  border: 1px solid grey;
  position: absolute;
  display: flex;
  padding: 5px;
  flex-flow: column;
  left: 15px;
  background: white;
  top: -20px;

  .gov-button,
  button {
    font-size: 0.625rem !important;
  }
}

//a:focus, .gov-link:focus, .gov-fileinput__attachments > li > span:focus, .gov-portal-nav__link:focus, .gov-sortable-table__trigger:focus,
//button:focus, .gov-button:focus, .gov-hamburger:focus, .gov-calendar__days tbody tr td button:focus, .gov-calendar__toggle:focus {
//  box-shadow: none !important;
//  outline: 0;
//}

.btnNoAfterIcon {
  &:after {
    display: none !important;
  }
}

.selectAutocompleteContainer {
  .gov-select {
    border: 1px solid var(--gov-color-grey-mid);
    border-radius: 3px;

    &:after {
      display: none;
    }
  }

  .sa-dropdownIndicator,
  .sa-clearIndicator,
  .sa-indicatorSeparator {
    display: none;
  }

  .sa-valueContainer {
    padding: 1.5625rem 1.25rem 0.6875rem;
  }

  .sa-loadingIndicator {
    right: 30px;
    position: absolute;
  }

  .sa-menu {
    margin: 0;
    border-radius: 0;
    z-index: 100;
  }

  .sa-menuList {
    padding-bottom: 0;
    padding-top: 0;
  }

  .sa-option {
    padding: 6px 12px;
  }

  .selectAutocompleteInput {
    margin: 0;
    padding: 0;

    input {
      font-size: 1rem !important;
      letter-spacing: 1.02px !important;
      line-height: 1.3 !important;
      box-shadow: none !important;
      min-width: 160px !important;
    }
  }
}

.gov-form-control--error .selectAutocompleteContainer .gov-select {
  color: var(--gov-color-error) !important;
  border-color: var(--gov-color-error);

  * {
    color: var(--gov-color-error);
  }
}

.selectAutocompleteContainer.disabledAutocomplete .gov-select {
  background-color: var(--gov-color-grey-bg);
  border-color: var(--gov-color-grey-light);
  color: var(--gov-color-grey-light);
  cursor: not-allowed !important;
}

.gov-form-control select {
  min-width: 110px;
}

.sentInfoLineTitle {
  display: inline-block;
  min-width: 250px;
}

.gov-select.selectDisabled:after {
  display: none !important;
}

.inputHelp {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 2;
}

.radioAlone {
  .gov-form-control--custom {
    display: inline-block;
  }

  .inputHelp {
    position: absolute;
    top: -10px;
    right: -20px;
  }
}

.react-datepicker-popper {
  z-index: 99;

  .react-datepicker__triangle {
    margin-left: -15px!important;
  }
}

.gov-form-control__label {
  background: transparent !important;
}

textarea.gov-form-control__input {
  padding-top: 2.2rem;

  & + label {
    margin-right: 25px;
  }

  &:disabled + label {
    cursor: not-allowed;
  }
}

.gov-form-control:not(.gov-form-control--custom ) {
  input {
    padding-top: 1.9rem;
    padding-bottom: 0.3rem;
  }

  .gov-form-control__label {
    line-height: 1;
    padding-right: 1rem;
  }
}

.gov-form-control select {
  padding-top: 1.9rem;
  padding-bottom: 0.3rem;
}

main#content {
  min-height: 500px;
}

.error-ref-element {
  color: var(--gov-color-error);
}

.hp-news-links {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  column-gap: 20px;
  @media (max-width: 57.5em) {
    flex-direction: column;
    row-gap: 15px;
  }
}

header.ref-header {
  background-color: var(--gov-color-grey-mid);

  .gov-header__separator {
    border-bottom: 1px solid var(--gov-color-grey-light);
  }

  .gov-header__nav {
    background-color: var(--gov-color-grey-mid);
  }
}

.ref-header-logo {
  flex-flow: column;
  align-items: baseline;

  small {
    color: var(--gov-color-yellow) !important;
    font-weight: bold;
  }
}

.hp-intro-block {
  max-width: 1040px;

  .hp-intro-title {
    font-size: 1.5rem !important;
  }

  .hp-intro-text {
    font-size: 1rem !important;
    display: block !important;
  }

  .hp-intro-link {
    font-size: initial !important;
    color: white !important;
  }
}

.mmr-logo {
  margin-right: 25px;
  width: 80px;
  @media screen and (min-width: 31.25em) {
    width: 130px;
  }
}

.cellContractName {
  max-width: 320px;
  white-space: normal;
  line-height: 1.1 !important;
}
