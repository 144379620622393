.u-color--grey-dark {
    color: gov-colors('grey-dark');
}

.u-color--grey-mid {
    color: gov-colors('grey-mid');
}

.u-color--grey-light {
    color: gov-colors('grey-light');
}

.u-color--white {
    color: gov-colors('white');
}
