.gov-breadcrumbs {
    border-bottom: 1px solid #dddddd;
	&__item + &__item {
		&:before {
			@include pseudo(inline-block, relative, '\65');

            color: gov-colors('grey-dark');
			font-family: $gov-icon-font;
			font-size: rem-calc(24);
			text-transform: none;
			vertical-align: middle;

            opacity: .5;
		}
    }
}
