$menuWidth: 300px;
$menuCloseWidth: 45px;
.container__fullwidth {
  //max-width: 1400px !important;

  .formWrap {
    display: flex;

    &.formWrapDesktop {

      flex-flow: row;

      .formsContentWrap {
        width: calc(100% - $menuWidth);
      }

      .formnav-close + .formsContentWrap {
        width: calc(100% - $menuCloseWidth);
      }
    }

    &.formWrapMobile {

      flex-flow: column;

      .formsContentWrap {
        width: 100%;
      }
    }
  }

}

// jen na mobilu
.formnavigationButton {
  position: sticky;
  left: 0px;
  margin-left: -23px;
  margin-bottom: -20px;
  width: 20px;
  top: 6px;
  z-index: 999;
  padding: 0;
}

.formnavigation {
  position: sticky;
  top: 0px;
  overflow-y: auto;

  &.formnavigationDesktop {
    flex: 0 0 $menuWidth;
    z-index: 10;
    max-height: 90vh;
    padding-right: 10px;
    margin-right: 10px;

    &.formnav-close {
      flex: 0 1 $menuCloseWidth;
      overflow: hidden;
    }
  }

  &.formnavigationMobile {
    z-index: 105;
    max-height: 100vh;

    &.formnav-close {
      height: auto;
    }
  }

  .formnav-btn {
    z-index: 5;
    padding: 0px;
    box-shadow: none;
    width: 100%;
    background: $c-gui-blue-light;
    justify-content: end;
  }

  ul {
    margin-left: 0;
    margin-bottom: 0px;

    background: $c-gui-blue-light;

    &.navblock > li {
      text-transform: uppercase !important;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $c-gui-blue;

      & > .nav-list-item > .gov-link {
        text-transform: uppercase;
      }
    }

    .nav-title {
      background: $c-gui-blue;
      color: white;
      text-transform: uppercase;
      padding: 5px 15px !important;
      font-size: 80%;

      button {
        padding: 3px;
        color: white;
        float: right;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }


    & ul {
      margin-left: 8px;
      font-size: 80%
    }

    li {
      &:before {
        display: none;
      }

      .nav-list-item {
        display: flex;

        .nav-list-toggle-space {
          width: 20px;
          display: block;
          line-height: 0;
          flex: 0 0 auto;
          text-align: center;

          .nav-list-toggle {
            padding: 0px 2px;
            height: 16px;
            font-size: 14px;
            box-shadow: none;
          }
        }
      }

      a.gov-link {
        transition: all 0.3s;
        line-height: 1.3;
        padding: 2px 0;

        overflow: hidden;
        display: flex;

        text-transform: none;

        &.active {
          font-weight: bold;
          text-decoration: underline;
        }

        .nav-link-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .err-count-nav {
          margin: 0 5px;
          color: var(--gov-color-error);
        }
      }
    }
  }
}


.formActionBlock {
  text-align: center;

  button {
    margin: 0 10px 20px 10px;
    width: 245px;
  }

}

.gov-form-control {
  margin-bottom: 18px;
  margin-right: 5px;
  margin-left: 5px;
}

//.gov-form-control:not(.gov-form-control--custom) {
//  overflow: hidden;
//}

.formElementWrap {

  &:last-child {
    .gov-form-control {
      margin-bottom: 10px;
    }
  }
}

//.rowSectionWrap + .repeatSectionWrap,
.formElementWrap + .repeatSectionWrap,
  //.rowSectionWrap + .groupSectionWrap,
.formElementWrap + .groupSectionWrap,
{
  margin-top: 10px;
}

.arrayAddWrap {
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 25px;
}


.btnInlineWrap {
  display: inline-block;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
}

.identifier-badge {
  background: $c-gui-yellow;
  color: black;
  margin-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
}

.bulb-info-wrap {
  position: absolute;
  right: 8px;
  top: 32px;
  z-index: 75;
}

$title-zindex: 90;
$title-height: 45px;

.wizard-form {
  .custom-form__title {
    z-index: 20;
  }

  .form-title-toggle {
    display: none;
  }

  .nav-anchor-section {
    position: relative;
  }

  .section-header-nav {
    display: none;
  }
}

.formsContentWrap {

  .section-header-nav {
    display: block;
    margin-left: auto;

    a * {
      color: black;
      margin-bottom: -3px;
    }
  }

  .err-count-header {
    margin: 0 10px 0 10px;
    background: var(--gov-color-error);
    color: white;
    border-radius: 50px;
    min-width: 18px;
    height: 18px;
    padding-left: 1px;
    line-height: 18px;
    display: inline-block;
    text-align: center;
    font-size: 0.75rem;
  }

  .section-header-btn {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .section-header-btn + .section-header-nav {
    margin-left: 20px;
  }

  .nav-anchor-section:not(.blockWrap  .blockWrap .blockWrap  .nav-anchor-section) + .formElementWrap {
    padding-top: 20px !important;
  }

  .formElementWrap:not(.blockWrap  .blockWrap .blockWrap  .formElementWrap) {

    &:not(.custom-form__wrap--special-60 .formElementWrap) {
      padding: 0px 20px 1px 20px;

      &:first-child {
        padding-top: 10px;
      }
    }

  }

  .sticky-title {
    background: white !important;
    color: black;
    font-size: 21px !important;
  }

  .custom-form {
    padding: 0px;
    border: none;
    background: white;
  }

  .active-section .custom-form__title:not(.active-section .blockWrap .custom-form__title) {
    border: 2px solid $c-gov-primary-500;
    border-bottom: none;
  }

  .active-section .blockWrap:not(.active-section .blockWrap .blockWrap) {
    border: 2px solid $c-gov-primary-500 !important;
    border-top: none !important;
  }

  .blockWrap {
    margin-bottom: 15px !important;

    .sticky-title {
      background: $c-gov-primary-100 !important;
      font-size: 16px !important;
    }

    .custom-form {
      padding: 10px 10px 10px 10px;
      border: 1px solid $c-gov-primary-100;
      border-top: none;
      background: white;
    }
  }


  .blockWrap .blockWrap {
    margin-bottom: 10px !important;

    .sticky-title {
      background: $c-gov-primary-200 !important;
    }

    .custom-form {
      padding: 10px 10px 5px 10px;
      border-color: $c-gov-primary-200;
      border-top: none;
    }
  }

  .blockWrap .blockWrap .blockWrap {
    .sticky-title {
      background: $c-gov-primary-300 !important;
    }

    .custom-form {
      border-color: $c-gov-primary-300;
    }
  }

  .blockWrap .blockWrap .blockWrap .blockWrap {

    .sticky-title {
      background: $c-gov-primary-400 !important;
    }

    .custom-form {
      border-color: $c-gov-primary-400;
    }
  }

  .blockWrap .blockWrap .blockWrap .blockWrap .blockWrap {
    .sticky-title {
      background: $c-gov-primary-500 !important;
    }

    .custom-form {
      border-color: $c-gov-primary-500;
    }
  }

  @media (min-width: 52em) {
    .custom-form__wrap--special-60 > :first-child {
      flex: 0 0 auto;
      width: calc(60% - 10px);
    }
  }

  .custom-form__title {
    text-transform: none;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    position: relative;
    //position: sticky;
    //z-index: $title-zindex;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    background: gov-colors("blue-light");
    color: black;
    font-weight: bold;
    letter-spacing: 0;
    padding: 2px 20px;

    height: $title-height;

    .form-title-text {
      max-width: calc(100% - 300px);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .form-title-toggle {
      color: black;
      margin-left: -10px;
      padding: 5px 5px;
      margin-right: 5px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    small {
      padding-left: 5px;
      padding-top: 3px;
      font-weight: normal;
    }
  }

  .blockWrap .blockWrap .blockWrap .custom-form__title {
    height: 34px;
    font-size: 15px;
  }

  $title_max_sticky_level: 2;

  @for $i from 0 through $title_max_sticky_level - 1 {
    $topValue: $title-height * $i;
    $zIndexValue: $title-zindex - $i;
    // Použití selektoru
    .sticky-title-level-#{$i} {
      position: sticky;
      top: $topValue;
      z-index: $zIndexValue;
    }
  }


  .gov-button--primary-form {
    @extend .gov-button--primary-outlined;
    font-weight: bold;
    background: white;

    &:hover {
      background-color: gov-colors("blue-hover");
    }
  }

  .gov-button--primary-green {
    @extend .gov-button--primary;
    font-weight: bold;
    background-color: $c-gui-green;
    color: white;
    border: none;

    &:hover {
      background-color: $c-gui-green-hover;
    }
  }

  .gov-button--remove-form {
    @extend .gov-button--primary-outlined;

    background: white;
    color: gov-colors("error");
    border-color: gov-colors("error");

    padding: 0.4rem 0.625rem;

    &:hover {
      background-color: gov-colors("blue-hover");
      color: gov-colors("error");
    }
  }


  textarea.gov-form-control__input + label {
    background-color: #fffff0 !important; // $c-gov-yellow-100;
  }

  .gov-form-control select,
  .gov-form-control--custom .gov-form-control__indicator,
  [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number],
  [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
    background-color: #fffff0 !important; // $c-gov-yellow-100;
    font-weight: 500;
  }

  textarea.gov-form-control__input:disabled + label,
  .gov-form-control select:disabled, .gov-form-control select[aria-disabled=true],
  .gov-form-control--custom .gov-form-control__radio[aria-disabled=true] ~ .gov-form-control__indicator,
  .gov-form-control--custom .gov-form-control__radio:disabled ~ .gov-form-control__indicator,
  .gov-form-control--custom .gov-form-control__radio[readonly] ~ .gov-form-control__indicator,
  [type=color]:disabled, [type=color][aria-disabled=true], [type=date]:disabled,
  [type=date][aria-disabled=true], [type=datetime-local]:disabled,
  [type=datetime-local][aria-disabled=true], [type=datetime]:disabled, [type=datetime][aria-disabled=true],
  [type=email]:disabled, [type=email][aria-disabled=true], [type=month]:disabled, [type=month][aria-disabled=true],
  [type=number]:disabled, [type=number][aria-disabled=true], [type=password]:disabled,
  [type=password][aria-disabled=true], [type=search]:disabled, [type=search][aria-disabled=true],
  [type=tel]:disabled, [type=tel][aria-disabled=true], [type=text]:disabled, [type=text][aria-disabled=true],
  [type=time]:disabled, [type=time][aria-disabled=true], [type=url]:disabled, [type=url][aria-disabled=true],
  [type=week]:disabled, [type=week][aria-disabled=true], textarea:disabled, textarea[aria-disabled=true] {
    background-color: $c-gov-secondary-300 !important;
  }

  .gov-form-control--custom .gov-form-control__label {
    font-weight: 400;
  }

}

textarea{
  resize: none;
}

.textarea_char_limit {
  position: absolute;
  margin-top: -20px;
  right: 10px;
}


.custom-form-txt {
  padding: 15px;

  .custom-form__title, .custom-form__inner-title {
    position: relative;
    top: -4px;
    display: inline-block;
    left: 5px;
    font-size: 0.75rem;
  }
}

.closed-form-block {
  display: none;
}

//
.gov-form-control__label {
  //color: $c-gov-primary-800 !important;
  color: black !important;
}

[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month],
[type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea,
.gov-form-control select,
.gov-form-control--custom .gov-form-control--custom .gov-form-control__label {
  color: black !important;
}

.gov-form-control--custom .gov-form-control__radio[aria-disabled=true] ~ .gov-form-control__indicator,
.gov-form-control--custom .gov-form-control__radio:disabled ~ .gov-form-control__indicator,
.gov-form-control--custom .gov-form-control__radio[readonly] ~ .gov-form-control__indicator {
  border-color: $c-gov-primary-300;
}

.custom-detail__alert {
  background: $c-gui-yellow-light;
  border: 1px solid $c-gui-yellow;
  padding: 20px;
}

.scrollinform {
  position: relative;

  &:after {
    color: $c-gui-blue;
  }
}

.gov-form-control__message {
  color: var(--gov-color-grey-dark);
}


.Toastify__toast-body {
  white-space: pre-line;
}

.adminUserLink {
  flex-flow: column;

  small {
    display: block;
    color: var(--gov-color-yellow)!important;
  }
}
