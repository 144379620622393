
html {
    min-height: 100vh;
}


body {
    min-height: 100vh;

    font-family: $gov-font-family-primary;
    font-size: rem-calc(15);
    background: #F6F5F5;
}

.pl-c-main {
	background: #f5f5f5;
}
