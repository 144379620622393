$class: '.gov-cookie-bar';

#{$class} {
    position: fixed;
    left: 0; right: 0; bottom: 0;
    @include z-index('bar', 1);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__content {
        position: relative;
        z-index: 2;

        padding: rem-calc(20);

        background-color: gov-colors('white');
        box-shadow: 0 rem-calc(1) rem-calc(40) rgba(59, 59, 59, 0.4);

        &-inner {
            display: block;
            margin: 0 auto;
            max-width: $gov-container-width;

            p {
                margin-top: rem-calc(15);
                margin-bottom: rem-calc(5);
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;

        .gov-button {
            margin-top: rem-calc(20);
        }

        &-primary {
            .gov-button:first-child {
                margin-right: rem-calc(30);
            }
        }
    }

    @include mq($until: mobile-landscape) {
        &__actions {
            display: block;

            &-primary {
                margin-bottom: rem-calc(10);
            }
        }
    }

    @include mq($until: mobile) {
        &__actions {
            display: block;
        }
    }
}
